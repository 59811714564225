<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
        <v-container fluid>
            <!-- <v-row no-gutters>
         <v-col cols="10"></v-col>
         <v-col cols="2">
             <v-btn cols="2" class="success" @click.stop="onDownload">
                 <v-icon>get_app</v-icon>
                 Export
             </v-btn>
         </v-col>
     </v-row>-->
            <v-expansion-panels multiple hover>
                <v-expansion-panel>
                    <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container fluid>
                            <v-row col="12">
                                <v-row align="start" justify="start" class="mt-4">

                                    <v-col class="d-flex" cols="12" sm="3">
                                        <v-autocomplete v-model="serverParams.ProvinceID"
                                                        :items="ProvinceList"
                                                        clearable
                                                        dense
                                                        outlined
                                                        item-text="value"
                                                        item-value="id"
                                                        @change="getDistrictList"
                                                        :label="$t('table.province')"></v-autocomplete>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="3">
                                        <v-autocomplete v-model="serverParams.DistrictName"
                                                        :items="DistrictList"
                                                        clearable
                                                        dense
                                                        outlined
                                                        item-text="value"
                                                        item-value="value"
                                                        :label="$t('form.district')"></v-autocomplete>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="3">
                                        <v-autocomplete v-model="serverParams.CategoryName"
                                                        :items="hotelCategory"
                                                        item-text="value"
                                                        item-value="value"
                                                        clearable
                                                        dense
                                                        outlined
                                                        :label="$t('hotel_category')"></v-autocomplete>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="3">
                                        <v-text-field v-model="serverParams.HotelName"
                                                      clearable
                                                      dense
                                                        outlined
                                                      :label="$t('hotel_name')"></v-text-field>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="12">
                                        <v-spacer></v-spacer>
                                        <v-btn class="mx-2" dark small color="primary" @click="loadItems">
                                            <v-icon dark> search</v-icon>
                                            {{$t('search')}}
                                        </v-btn>
                                        <v-btn @click.stop="onDownload" small class="success mx-4">
                                            <v-icon>get_app</v-icon>
                                            {{$t('download_to_excel')}}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                    </v-col>

                                </v-row>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-simple-table class="myCustomTable">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <td v-for="header in columns" :key="header.field">
                                {{header.label}}
                            </td>
                        </tr>

                    </thead>
                    <tbody>

                        <template v-for="(items,index) in rows">
                            <tr :key="'key'+index">
                                <td>{{items.provinceName}}</td>
                                <td>{{items.districtName}}</td>
                                <td>{{items.name}}</td>
                                <td>{{items.categoryName}}</td>
                                <td>{{items.subcategoryName}}</td>
                                <td>{{items.renewedDate}}</td>
                            </tr>
                            <template v-for="(item,count) in items.data">
                                <tr :key="'item'+count">
                                    <td v-for="(column,key) in item" :key="key">{{column}}</td>
                                </tr>
                            </template>
                        </template>

                    </tbody>
                </template>
            </v-simple-table>
            <v-row class="mt-7">
                <div class="col-12" style="color: gray; text-align: right">
                    Source : Department of Tourism
                </div>
            </v-row>
            <v-snackbar v-model="snackbar.snackbar"
                        color="success"
                        :timeout="snackbar.timeout"
                        top>
                {{ callbackResponse.message }}
                <v-btn dark
                       text
                       @click="snackbar.snackbar = false">
                    Close
                </v-btn>
            </v-snackbar>
        </v-container>
    </v-content>
</template>

<script>
    import axios from "axios";

    export default {
        name: "AirportDomesticMovementList",
        props: [
            'moduleName'
        ],
        components: {},
        computed: {
            reportIsMonthWise() {
                return this.serverParams.ReportType === "monthwise";
            },
            items() {
                return [
                    {
                        text: this.$t('report'),
                        exact: true,
                        to: '/reportModules'
                    },
                    {
                        text: this.$t('type'),
                        exact: true,
                        to: '/reportModules/reportDot/dotHotel'
                    },
                    {
                        text: this.$t('renewed_hotel_report'),
                        exact: true,
                        disabled: true
                    },
                ]
            },
            columns() {
                return [
                    {
                        label: this.$t('province'),
                        field: 'provinceName',
                    },
                    {
                        label: this.$t('district'),
                        field: 'districtName',
                    },
                    {
                        label: this.$t('hotel_name'),
                        field: 'name',
                    },

                    {
                        label: this.$t('table.category'),
                        field: 'categoryName',
                    },
                    {
                        label: this.$t('sub_category'),
                        field: 'subCategoryName',
                    },
                    {
                        label: this.$t('renewed_date'),
                        field: 'renewedDate',
                    },
                ]
            }
        },
        methods: {
            async getDistrictList(val) {
                let { data } = await axios.get('District/GetDistrictListByProvince/' + val)
                console.log("district: ", this.data)
                this.DistrictList = data

            },
            async getProvinceList() {
                const ProvinceList = await axios.get('Province/DDLProvinceList')
                this.ProvinceList = ProvinceList.data
            },


            getNow: function () {
                const today = new Date();
                const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                /* const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();*/
                this.serverParams.FromDate = date;
                this.serverParams.ToDate = date;
            },
            formResponse(data) {
                console.log({ data })
                if (data.message) {
                    this.snackbar.formModal = false
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.callbackResponse = data;
                    this.snackbar.snackbar = true
                    this.loadItems();
                } else {
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.snackbar.formModal = false
                }
            },

            async onDownload() {
                let param = {
                    CategoryName: this.serverParams.CategoryName,
                    HotelName: this.serverParams.HotelName,
                    ProvinceID: this.serverParams.ProvinceID != null && this.serverParams.ProvinceID != undefined ? parseInt(this.serverParams.ProvinceID) : 0,
                    DistrictName: this.serverParams.DistrictName,
                };
                await axios({
                    url: 'Hotel/DownloadRenewedHotel',
                    method: 'POST',
                    responseType: 'blob',
                    data: param
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'HotelRenew.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                });
            },

            dialogueClose() {
                this.historyData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
            },
            async loadItems() {
                this.snackbar.isLoading = true;
                let param = {
                    CategoryName: this.serverParams.CategoryName,
                    HotelName: this.serverParams.HotelName,
                    ProvinceID: this.serverParams.ProvinceID != null && this.serverParams.ProvinceID != undefined ? parseInt(this.serverParams.ProvinceID) : 0,
                    DistrictName: this.serverParams.DistrictName,
                };
                axios.post('Hotel/GetDotHotelRenewData', param)
                    .then(response => {
                        this.rows = response.data;
                        // this.totalRecords = response.data.totalCount;
                        this.snackbar.loading = false;
                    })
                    .catch(err => {
                        console.log(err)
                    });

            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage });
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },

            editItem(item) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.historyData.editItemIndex = item.personInformationID
            },

            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Mountain/DeleteHistoryInformationByIDAsync/" + props.personInformationID;
            },
        },
        mounted() {
            this.getNow()
            this.loadItems()
            this.loadFilterData()

        },
        created() {
            this.getProvinceList();
        },
        data() {
            return {
                AirportList: [],
                hotelCategory:
                    [{ id: "Hertiage Boutique", value: "Hertiage Boutique" },
                    { id: "Resort", value: "Resort" },
                    { id: "Stars 1", value: "Stars 1" },
                    { id: "Star 2", value: "Star 2" },
                    { id: "Star 3", value: "Star 3" },
                    { id: "Star 4", value: "Star 4" },
                    { id: "Star 5", value: "Star 5" },
                    { id: "Star 5(Deluxe)", value: "Star 5(Deluxe)" },
                    ],
                tab: 'tab-monthlyReport',
                callbackResponse: {
                    timeout: 6000
                },
                rows: [],
                ReportTypes: [
                    {
                        id: "yearwise", value: "yearwise"
                    },
                    {
                        id: "monthwise", value: "monthwise"
                    }
                ],
                dialogDelete: false,
                deleteFormData: [],
                formdata: [],
                ProvinceList: [],
                DistrictList: [],
                historyData: {
                    totalRows: 0,
                    editItemIndex: null
                },
                serverParams: {
                    CategoryName: "",
                    HotelName: "",
                    ProvinceID: 0,
                    DistrictName: "",
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "id",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 10,
                    ReportType: ""
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    /* breadcrumbsItem: [
                         {
                             text: 'Dashboard',
                             disabled: false,
                             to: 'MountaineeringDashboard',
                             exact: true

                         },
                         {
                             text: 'Mountaineering History Information',
                             disabled: true,
                         },
                     ],*/
                    dynamicComponent: {
                        current: 'HistoryForm'
                    }
                }
            }
        },
    }
</script>

<style scoped lang="scss">
    $blue: #265b93;

    .myCustomTable {
        max-width: 100%;

        thead {
            background-color: $blue;
            color: white;
        }

        tbody {
            height: 100px; /* Just for the demo          */
            overflow-y: auto; /* Trigger vertical scroll    */
        }

        td, th {
            padding: 1rem
        }

        border: 1px solid #eee;

        tr, td, th {
            border: 1px solid #eee;
        }
    }

    .table-container {
        width: 100%;
    }

    .headerBackground {
        background-color: $blue;
        color: white;
    }
</style>